import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/logo.png";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/linkedin-icon.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram.svg";

const Container = tw(ContainerBase)`bg-secondary-200 text-white -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-800 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-primary-800 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const ParentLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-primary-800 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-100`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>The Property People</LogoText>
            
         </LogoContainer>
         <ParentLink target="_blank" href="https://caliclaimslaw.com/">Parent company of CaliClaims Law, LLP</ParentLink>
          <LinksContainer>
      <Link href="/">Home</Link>
      <Link href="/about">About Us</Link>
      <Link href="/claim-types">Claim Types</Link>
      <Link href="/contact">Contact Us</Link>
      
          </LinksContainer>
          
          <SocialLinksContainer>
            <SocialLink target="_blank" href="https://www.facebook.com/The-Property-People-106820197640250">
              <FacebookIcon />
            </SocialLink>
            <SocialLink target="_blank" href="https://twitter.com/4propertypeople">
              <TwitterIcon />
            </SocialLink>
            <SocialLink target="_blank" href="https://www.linkedin.com/in/propertypeoplelaw/">
              <YoutubeIcon />
            </SocialLink>
            <SocialLink target="_blank" href="https://www.instagram.com/propertypeoplelaw">
              <InstagramIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>80 SW 8th St, Suite 2590, Miami, FL 33130 </CopyrightText>
          <CopyrightText>
            &copy; Copyright 2020, The Property People FL, PA. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
