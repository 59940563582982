import React, {useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import {SectionDescription} from "components/misc/Typography";
import { ReactComponent as TwitterIcon} from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon} from "images/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "images/github-icon.svg";
import DanielIlani from "images/DannyIlani.jpg";
import NicoleHouman from "images/NicoleHouman.jpg";
import AliciaPokhoy from "images/AliciaPokhoy.jpg";
import AnnetteVera from "images/AnnetteVera.jpg";
import BrandySloan from "images/BrandySloan.jpg";
// import BrianNeary from "images/BrianNeary.jpg";
import ChristinaMiranda from "images/ChristinaMiranda.jpg";
import GenevieveSloan from "images/GenevieveSloan.jpg";
// import SophiaAmaro from "images/SophiaAmaro.jpg";

const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`
const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-64 h-64 bg-contain bg-center rounded`}
  background-repeat: no-repeat
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`

const query = `
query {
    teamCollection(limit: 1) {
      items {
        name
        employeesCollection {
          items {
            ... on Employee {
              name
              position
              headshot {
                url
              }
            }
          }
        }
      }
    }
  }
  
`;

function App() {
  const [page, setPage] = useState(null);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/39shuj7k3ong?order=fields.name&content_type=cat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer 1OUxPJdwMsjU4KBsQOAgXA6YXx90voYKTZjY8ZCuRyk",
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        console.log(data)
        if (errors) {
          console.error(errors);
        }
        data.teamCollection.items[0].employeesCollection.items.forEach(function(item){
        
        })
        setPage(data.teamCollection.items[0].employeesCollection);

      });

  }, []);

  // render the fetched Contentful data

  const heading = "Meet The Team"
  const subheading = "Our Team"
  const description = ""
//   const cards = [
//     {
//       imageSrc: DanielIlani,
//       position: "Founding Attorney",
//       name: "Daniel Ilani",
     
//     },
//     {
//       imageSrc: NicoleHouman,
//       position: "Founding Attorney",
//       name: "Nicole Houman",
     
//     },
//     {
//       imageSrc: AliciaPokhoy,
//       position: "Attorney",
//       name: "Alicia Pokhoy",

//     },
//     {
//       imageSrc: AnnetteVera,
//       position: "Attorney",
//       name: "Annette Vera",
     
//     },
    
//     {
//       imageSrc: BrandySloan,
//       position: "Attorney",
//       name: "Brandy Sloan",
  
//    },
//     {
//       imageSrc: "",
//       position: "Attorney",
//       name: "Brian Neary",
  
//    },
//     {
//       imageSrc: ChristinaMiranda,
//       position: "Law Clerk",
//       name: "Christina Miranda",
     
//     },
//     {
//       imageSrc: GenevieveSloan,
//       position: "Legal Intern",
//       name: "Genevieve Sloan",
     
//     },
//     {
//       imageSrc: "",
//       position: "Legal Intern",
//       name: "Sophia Amaro",
     
//     }
//   ]
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading> }
          {description && <Description>{description}</Description> }
        </HeadingContainer>
        {!page ? (<span>Loading...</span>) : (
        <Cards>
        { page.items.length ? page.items.map((employee, index) => (  
            <Card key={index}>
              {employee.headshot ?(
                <CardImage imageSrc={employee.headshot.url} />
              ) : (null) }
              <CardContent>
                <span className="position">{employee.position}</span>
                <span className="name">{employee.name}</span>
              </CardContent>
            </Card>
          )) : (
            <span>Loading...</span>
          )}
        </Cards>
        )}
      </ContentWithPaddingXl>
    </Container>
  );
};


export default App;